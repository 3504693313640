import React from 'react'
import Flip from 'react-reveal/Fade'

const Banner = (props) => {
  return (
    <>
      <div className="banner-cont">
        <div className="content-overlay">
          <img
            src={require('../../assets/images/logo-crop.png')}
            alt=""
            className="logo-crop rotate"
          />
          <div className="content-box">
            <span className="text">
              AK PACKERS AND MOVERS
              <br />
              <span className="items-highlighter"> YOUR MOVE OUR PASSION</span>
            </span>
          </div>
        </div>
      </div>
      <Flip left>
        <div className="contact-cont">
          <div className="contact-box">
            <div className="text-1">Phone</div>
            <div className="text-2">+91-98864 15786 | +91-94489 71220 </div>
          </div>
          <div className="contact-box">
            <div className="text-1">Email</div>
            <div className="text-2">aklsdvg@gmail.com</div>
          </div>
          <div className="contact-box cont-2">
            <div className="text-1">Our Location</div>
            <div className="text-2 ">
              N.M.E. Building, Onion Market, P.B. Road, Davanagere - 577001.
            </div>
          </div>
        </div>
      </Flip>
    </>
  )
}

export default Banner
